
.app__skills {
  flex: 1;
  width: 100%;
  height: fit-content;
  flex-direction: column;
  h2 {
    padding-top: 5rem;
  }
}

.app__skills-container {
  width: 100%;
  margin-top: 3rem;
  //margin-bottom: 3rem;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
}

.app__skills-container2 {
  width: 100%;
  //margin-bottom: 3rem;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
  @media screen and (min-width: 2000px) {
    margin-top: 8rem;
  }
}

.app__skills-list {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  //margin-right: 5rem;

  @media screen and (max-width: 900px) {
    margin-right: 0;
    justify-content: center;
    align-items: center;
  }
}

.app__skills-item {
  flex-direction: column;
  text-align: center;
  margin: 1rem;
  transition: all 0.3s ease-out;

  div {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: white;

    img {
      width: 50%;
      height: 50%;
    }

    &:hover {
      box-shadow: 0 0 25px lightgrey;
    }

    @media screen and (min-width: 2000px) {
      width: 150px;
      height: 150px;
    }
    @media screen and (max-width: 2000px) {
      width: 70px;
      height: 70px;
    }
  }

  p {
    font-weight: 500;
    margin-top: 0.5rem;
  }

  @media screen and (min-width: 2000px) {
    margin: 1rem 2rem;

    p {
      margin-top: 1rem;
    }
  }
}

// .app__skills-exp {
//   flex: 1;
//   display: flex;
//   justify-content: flex-start;
//   align-items: flex-start;
//   flex-direction: column;

//   @media screen and (max-width: 900px) {
//     margin-top: 2rem;
//   }
// }

// .app__skills-exp-item {
//   width: 100%;
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-start;
//   align-items: flex-start;
//   margin: 1rem 0;
// }

// .app__skills-exp-works {
//   flex: 1;

//   .app__skills-exp-work {
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     align-items: flex-start;
//     margin-bottom: 1rem;
//     cursor: pointer;

//     h4 {
//       font-weight: 500;
//     }

//     p {
//       font-weight: 400;
//       color: var(--gray-color);
//       margin-top: 5px;
//     }
//   }
// }

// .app__skills-exp-year {
//   margin-right: 3rem;

//   p {
//     font-weight: 800;
//     color: var(--secondary-color);
//   }

//   @media screen and (max-width: 450px) {
//     margin-right: 1rem;
//   }
// }

// .skills-tooltip {
//   max-width: 300px !important;
//   background-color: white !important;
// }

.ed-section {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 600px) {
    margin: 0;
  }
  @media screen and (min-width: 900px) {
    flex-direction: row;
    width: 40%;
    margin: none;
  }
  
}

.app__skills-ed {
  flex: 1;
  //display: flex;
  //flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 10px;

  img {
    border-radius: 50%;
    margin: 0 2rem;
  }

  p {
    width: 300px;
    @media screen and (min-width: 2000px) {
      width: 450px;
    }
  }

  @media screen and (max-width: 900px) {
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 450px) {
    margin-left: 0;
  }
}

.app__skills-ed-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-align: left;
  margin: 0rem 0rem;
  transition: all 0.3s ease-out;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &:hover {
      box-shadow: 0 0 25px lightgrey;
    }

    @media screen and (min-width: 2000px) {
      width: 150px;
      height: 150px;
    }
    @media screen and (max-width: 2000px) {
      width: 100px;
      height: 100px;
    }
    @media screen and (max-width: 600px) {
      width: 60px;
      height: 60px;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: row;
    justify-content: left;
    margin: 2rem .5rem 0 .5rem;

    h4 {
      width: 100%;
      text-align: left;
    }

    p {
      width: 100%;
      margin-top: 0.5rem;
      text-align: left;
    }
    img {
      margin: 0;
    }

    .img-school {
      width: 60px;
      justify-content: center;
      margin-right: 1rem;
      margin-left: 2rem;
    }
  }
  
}
