.app__skills-container {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  @media screen and (max-width: 900px) {
    display: block;
  }
}

.app__notables-container {
  //height: 100vh;

  #notableP2 {
    display: none;
  }
  @media screen and (max-width: 450px) {
    text-align: center;
    padding: 0rem 0rem;

    #notableP1 {
      display: none;
    }
    #notableP2 {
      display: flex;
    }
  }

  h2 {
    padding-top: 5rem;
    @media screen and (max-width: 600px) {
      padding: 5rem 3rem 0rem 3rem;
    }
  }
  .notables-heading {
    padding-top: 0;
    margin-top: 0;
    
  }
}

.app__notables {
  //flex: 1;
  //display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;

  .notables-desc {
    width: 80%;
    display: block;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    text-align: left;
    color: var(--gray-color);
    margin: 0rem 0rem;

    @media screen and (min-width: 900px) {
      margin-left: 2rem;
    }
    @media screen and (min-width: 2000px) {
      font-size: 1.5rem;
    }
  }

  @media screen and (min-width: 950px) {
    width: 80%;
    margin: auto;
  }
  @media screen and (min-width: 1200px) {
    width: 70%;
    margin: auto;
  }

  @media screen and (max-width: 900px) {
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 450px) {
    padding: 2rem;
  }
}

.app__notables-item {
  flex-direction: row;
  text-align: center;
  margin: 1.5rem 0rem;
  transition: all 0.3s ease-out;

  div {
    width: 20%;
    height: 20%;
    background-color: rgba(0, 0, 0, 0);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 2rem;

      &:hover {
        box-shadow: 0 0 25px lightgrey;
      }

      @media screen and (max-width: 900px) {
        width: 3rem;
        height: 3rem;
      }
    }

    @media screen and (min-width: 2000px) {
      width: 150px;
      height: 150px;
    }
    @media screen and (max-width: 2000px) {
      width: 70px;
    }
  }

  p {
    font-weight: 500;
    margin-top: 0.5rem;
    word-wrap: normal;
  }

  @media screen and (min-width: 2000px) {
    margin: 5rem 2rem;

    p {
      margin-top: 1rem;
    }
  }
}
