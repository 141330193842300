.app__navigation-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #cbcbcb;
  margin: 0.5rem;

  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: var(--secondary-color);
  }

  @media screen and (min-width: 2000px) {
    width: 20px;
    height: 20px;
  }
  @media screen and (max-width: 450px) {
    display: none;
  }
}

.app__navigation-dot-active {
  background-color: var(--secondary-color);
  transition: all 0.5s ease-in-out;

  -webkit-animation: pulse 2s infinite; /* Safari 4+ */
  -moz-animation: pulse 2s infinite; /* Fx 5+ */
  -o-animation: pulse 2s infinite; /* Opera 12+ */
  animation: pulse 2s infinite; /* IE 10+, Fx 29+ */
}

@keyframes pulse {
  0% {
    //background-color: #cbcbcb;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0);
  }
  50% {
    //background-color: var(--secondary-color);
    box-shadow: 0 0 4px var(--secondary-color);
  }
  100% {
    //background-color: #cbcbcb;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0);
  }
}


.hideNavDots {
  opacity: 0;
  pointer-events: none;
  transition: all .3s ease-in-out;
}
.showNavDots {
  opacity: 1;
  pointer-events: auto;
  transition: all 1s ease-in-out;
}