.app__navbar {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0rem 0rem;
  background: rgba($color: white, $alpha: 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba($color: white, $alpha: 0.18);
  position: fixed;
  top: 0;
  z-index: 2;
}

.app__navbar-logo {
  display: inline-block;
  justify-content: flex-start;
  align-items: center;
  padding: .4rem;

  img {
    width: 60px;
    height: 60px;

    // @media screen and (min-width: 2000px) {
    //   width: 100px;
    //   height: 100px;
    // }

    @media screen and (max-width: 800px) {
      //margin-left: -1rem;
    }
  }
}

.app__navbar-links {
  //flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  position: absolute;
  right: 2%;

  li {
    margin: 0 1rem;
    cursor: pointer;
    flex-direction: column;

    div {
      width: 5px;
      height: 5px;
      background: transparent;
      border-radius: 50%;
      margin-bottom: 5px;
    }

    a {
      color: var(--gray-color);
      text-decoration: none;
      flex-direction: column;
      text-transform: uppercase;
      font-weight: 500;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: var(--secondary-color);
      }
    }

    &:hover {
      div {
        background: var(--secondary-color);
      }
    }
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
}

.app__navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: fixed;
    right: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);

    svg {
        width: 70%;
        height: 70%;
        color: var(--white-color);
        cursor: pointer;
    }
    div {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 5;
        pad: 1rem;
        width: 50%;
        height: 100vh;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        background: url('../../assets/bgWhite.png');
        background-color: var(--white-color);
        background-size: cover;
        background-repeat: repeat;
        box-shadow: 0 0 20px rgba(168, 168, 168, .15);
        @media screen and (min-width: 600px) {
          width: 30%;
      }

        svg {
            width: 35px;
            height: 35px;
            color: var(--secondary-color);
            cursor: pointer;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            li {
                margin: 1rem;

                a {
                    color: var(--gray-color);
                    text-decoration: none;
                    font-size: 1rem;
                    text-transform: uppercase;
                    font-weight: 500;
                    transition: all 0.3s ease-in-out;
              
                    &:hover {
                      color: var(--secondary-color);
                    }
                  }
            }
        }

        @media screen and (min-width: 900px) {
            display: none;
        }
    }

    @media screen and (min-width: 900px) {
        display: none;
    }

    .HiX {
      margin: 1.4rem .8rem;
    }
}



