.social-barrier {
  width: 100%;
  height: 20%;
  position: fixed;
  bottom: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 1)
  );
  pointer-events: none;
  z-index: 10;

  @media screen and (max-width: 500px) {
    height: 10%;
  }
}

.app__social {
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  // background-color: rgba($color: white, $alpha: 0.25);
  // backdrop-filter: blur(4px);
  // -webkit-backdrop-filter: blur(4px);
  z-index: 10;

  .app__social-div {
    box-shadow: 0px 0px 6px rgb(156, 156, 156);
    pointer-events: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--white-color);
    margin: 0 0.2rem;
    border: 1px solid var(--lightGray-color);

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s ease-in-out;

    svg {
      width: 15px;
      height: 15px;
      color: var(--gray-color);
    }

    &:hover {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);

      svg {
        color: var(--white-color);
      }
    }

    @media screen and (min-width: 2000px) {
      width: 70px;
      height: 70px;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}

a {
  text-decoration: none;
  margin-top: 0.3rem;
}

.email-button {
  width: fit-content;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  background-color: rgb(218, 233, 251);
  transform: all 0.3s ease-in-out;

  img {
    width: 40px;
    height: 40px;
    margin-right: 0.5rem;
  }

  p {
    font-weight: 600;
  }

  a {
    text-decoration: none;
    font-weight: 600;
  }
  &:hover {
    background-color: rgb(195, 219, 247);
    box-shadow: 0 0 15px rgb(215, 215, 215);
  }
}
