#home-bg {
  position: relative;
  background: url("../../assets/bgIMG.png");
  background-size: cover;
  background-position: center;

  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }

  @media screen and (max-width: 1200px) {
    background-size: cover;
    background-repeat: no-repeat;
  }

}

.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;
  padding: 3rem 0rem 0;

  @media screen and (min-width: 2000px) {
    //padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  // @media screen and (max-width: 400px) {
  //   padding-top: 6rem;
  // }
}

.app__header-info {
  flex: 0.9;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  @media screen and (max-width: 2000px) {
    width: 100%;
    margin-right: 0rem;
  }
}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  padding-left: 2rem;
  

  .badge-cmp,
  .tag-cmp {
    padding: 1rem 2rem;
    margin-right: 5rem;
    border: var(--white-color);
    border-radius: 15px;
    flex-direction: row;
    width: auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);

    &:hover {
      scale: 1.05;
      transition: all .5s ease-in-out;
    }
  }

  .badge-cmp {
    margin-top: 30px;
    @media screen and (min-width: 1200px) {
      margin-top: -20vh;
    }
    @media screen and (max-width: 450px) {
      margin-top: 40px;
    }
  }

  .tag-cmp {
    flex-direction: column;
    margin-top: 3rem;

    p {
      width: 100%;
      text-transform: uppercase;
      text-align: right;
    }
  }

  span {
    font-size: 2.5rem;

    @media screen and (min-width: 2000px) {
      font-size: 5rem;
    }
  }

  @media screen and (max-width: 1200px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.app__header-circles {
  flex: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
  margin-left: 1rem;

  div {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: var(--white-color);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    img {
      width: 60%;
      height: 60%;
    }
  }

  div:nth-child(1) {
    width: 90px;
    height: 90px;
  }
  div:nth-child(2) {
    width: 120px;
    height: 120px;
  }
  div:nth-child(3) {
    width: 70px;
    height: 70px;
  }
  div:nth-child(4) {
    width: 100px;
    height: 100px;
  }
  div:nth-child(5) {
    width: 80px;
    height: 80px;
  }

  @media screen and (min-width: 2000px) {
    div:nth-child(1) {
      width: 210px;
      height: 210px;
    }
    div:nth-child(2) {
      width: 170px;
      height: 170px;
    }
    div:nth-child(3) {
      width: 200px;
      height: 200px;
    }
    div:nth-child(4) {
      width: 150px;
      height: 150px;
    }
    div:nth-child(5) {
      width: 130px;
      height: 130px;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;

    div {
      margin: 1rem;
    }
  }

  @media screen and (max-width: 600px) {
    div {
      margin: 0rem;
      margin-bottom: 2rem;
    }
    div:nth-child(1) {
      width: 46px;
      height: 46px;
      margin-top: 2rem;
    }
    div:nth-child(2) {
      width: 60px;
      height: 60px;
    }
    div:nth-child(3) {
      width: 40px;
      height: 40px;
      margin-top: 2rem;
    }
    div:nth-child(4) {
      width: 50px;
      height: 50px;
    }
    div:nth-child(5) {
      width: 44px;
      height: 44px;
      margin-top: 2rem;
    }
  }

  @media screen and (min-width: 1200px) {
    margin-left: 5rem;

    div:nth-child(2) {
      margin-top: 1rem;
    }
    div:nth-child(3) {
      margin-top: 1rem;
    }
    div:nth-child(4) {
      margin-top: 1rem;
    }
    div:nth-child(5) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}

.app__header-img {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  //align-items: flex-end;
  position: relative;

  img {
    width: 80%;
    height: 80%;
    object-fit: contain;
    z-index: 1;
  }

  .overlay_circle {
    position: absolute;
    // left: 0;
    right: 5.5%;
    bottom: 5%;
    z-index: 0;
    width: 90%;
    height: 90%;
    margin: auto;
  }

  @media screen and (max-width: 1200px) {
    margin: 0rem 0 2rem;
  }

  @media screen and (min-width: 600px) {
    width: 50%;
  }
}

.app__header-myHead {
  img {
    width: 100px;
    height: auto;
    position: absolute;
    top: 80px;
    right: 0;

    @media screen and (min-width: 1200px) {
      width: 120px;
      top: 100px;
    }
  }
}
